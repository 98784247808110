<template>
<div class="bg-white"> 
      <div class="bg-blue-50 md:m-auto justify-center py-16 pt-32">               
            <h1 class="text-center text-4xl font-semibold text-primary tracking-widest uppercase">Get in Touch <br class="xl:hidden">with US!</h1>   
            <p class="text-center text-lg p-5 font-semibold">We like to hear from you and we will update you with our latest offers and other discounts!</p>                                          
        </div>
        <div class="container m-auto">
            <div class="grid grid-cols-12 justify-center content-center justify-items">
                <div class="col-span-12 pt-5 xl:p-4 xl:pt-20">                    
                    <form id="contact-form" class="mb-4 md:flex md:flex-wrap md:justify-start px-5 xl:px-52 md:mx-32 xl:mx-48" action="https://send.pageclip.co/g8gScb9TYdBQycxmIJFeIOLt2dvMYKN6/contactform-qualon" method="post">                   
                        <label for="name" class="w-full p-1 font-medium tracking-widest">Name :</label>                   
                        <input  class="py-2 text-primary  rounded-xl w-full p-3 my-1 border-2 focus:border-secondary focus:border focus:outline-none transition ease-in-out duration-500" type="text" name="name" id="name" autocomplete="name" required>                                         

                        <label for="email" class="w-full p-1 pt-3 font-medium tracking-widest">E-Mail :</label>                   
                        <input  class="py-2 text-primary  rounded-xl w-full p-3 my-1 border-2 focus:border-secondary focus:border focus:outline-none transition ease-in-out duration-500" type="email" name="email" id="email" autocomplete="email" required>

                        <label for="subject" class="w-full p-1 pt-3 font-medium tracking-widest">Subject :</label>               
                        <input  class="py-2 text-primary  rounded-xl w-full p-3 my-1 border-2 focus:border-secondary focus:border focus:outline-none transition ease-in-out duration-500" type="text" name="subject" id="subject" required>

                        <label for="message" class="w-full p-1 pt-3 font-medium tracking-widest">Message :</label>                   
                        <textarea  class="py-2 text-primary  rounded-xl w-full p-3 my-1 border-2 focus:border-secondary focus:border focus:outline-none transition ease-in-out duration-500" type="text" name="message" id="message" rows="5" required></textarea>  

                        <div class="mt-6 text-xl text-center font-medium w-full flex justify-end text-white">                         
                            <button class="g-recaptcha btn-grad w-auto block items-center justify-center uppercase rounded-2xl font-semibold text-white px-10 py-2 hover:shadow-md tracking-widest" data-sitekey="6LfA-XMaAAAAANffQMKfS7FhnnOPrGvHmSInLcJO" data-callback='onSubmit' data-action='submit'>Send</button>                  
                        </div>                                                                                                                          
                                               
                    </form> 

                    <div class="container flex flex-wrap justify-center mt-10">
                        <div class="p-6 text-center">
                            <a class="bg-blue-50 p-8 rounded-3xl" href="tel:+94711549549" target="_blank" rel="noopener noreferrer"><svg xmlns="http://www.w3.org/2000/svg" class="w-12 inline-block m-auto" viewBox="0 0 512 512"><path d="M492.4 104.5c-19.6-27-54-51.4-97-68.7-42.4-17-92-26.6-139.2-26.8a382 382 0 0 0-139.4 26.3c-43 17-77.4 41.4-97 68.4-19 26-24.4 53.8-15.8 80.7 5.6 17.5 22 29.2 40.6 29.2l64 .1c12.8 0 24.7-5.6 33-15.3a42 42 0 0 0 9.2-34.5c-.1-.4-.1-.7-.2-1l-3.4-13A261 261 0 0 1 256 124a268 268 0 0 1 109 26.3l-3.7 14a42 42 0 0 0 9.1 34.6c8 9.8 20 15.4 33 15.4h64c18.6 0 35-11.7 40.6-29 8.7-27 3.3-55-15.6-81zM479 176a12 12 0 0 1-11.5 8h-64c-3.7 0-7-1.6-9.4-4.4-1.3-1.5-3.2-4.7-2.6-9l6.4-24.2c1.7-6.6-1-13.6-7-17a296 296 0 0 0-134.5-35.7H255a290 290 0 0 0-133.6 35.2 15 15 0 0 0-7 17l6.2 24.2c.6 4.4-1.3 7.5-2.6 9-2.3 2.8-5.8 4.4-9.4 4.4l-64-.1a12 12 0 0 1-11.5-8C27.5 158 31.3 140 44.4 122c35-47.7 123.7-82.2 211.3-82.2h.5c87.7.1 176.7 35 211.5 83 13 18 17 36 11.2 53.5zm-78.4 103.8l-47.7-50c-3-3-7-4.7-11-4.7h-31v-20.8c0-8.4-6.8-15.3-15.3-15.3s-15.3 6.8-15.3 15.3V225h-49v-20.8c0-8.4-6.8-15.3-15.3-15.3s-15.3 6.8-15.3 15.3V225H170a15 15 0 0 0-11 4.7l-47.7 50c-37.4 39.3-58 90.7-58 145v63c0 8.4 6.8 15.3 15.3 15.3h374.6c8.4 0 15.3-6.8 15.3-15.3v-63c0-54.2-20.6-105.7-58-145zM84 472.3v-47.6a179 179 0 0 1 49.6-123.9l43.2-45.3h158.6l43.2 45.3a179 179 0 0 1 49.4 124v47.6H84zM256 287a80 80 0 1 0 0 160 80 80 0 1 0 0-160zm0 129.4c-27.2 0-49.4-22.2-49.4-49.4s22.2-49.4 49.4-49.4 49.4 22.2 49.4 49.4-22.2 49.4-49.4 49.4z"/></svg>
                            <p class="inline-block pl-5 text-lg font-semibold tracking-wider uppercase">(+94) 711 549 549</p></a>
                        </div>
                        <div class="p-6 text-center">
                            <a class="bg-blue-50 p-8 rounded-3xl" href="mailto:hello@qualon.xyz" target="_blank" rel="noopener noreferrer"><svg id="Capa_1" enable-background="new 0 0 479.058 479.058" class="w-12 inline-block m-auto" viewBox="0 0 479.058 479.058" xmlns="http://www.w3.org/2000/svg"><path d="m434.146 59.882h-389.234c-24.766 0-44.912 20.146-44.912 44.912v269.47c0 24.766 20.146 44.912 44.912 44.912h389.234c24.766 0 44.912-20.146 44.912-44.912v-269.47c0-24.766-20.146-44.912-44.912-44.912zm0 29.941c2.034 0 3.969.422 5.738 1.159l-200.355 173.649-200.356-173.649c1.769-.736 3.704-1.159 5.738-1.159zm0 299.411h-389.234c-8.26 0-14.971-6.71-14.971-14.971v-251.648l199.778 173.141c2.822 2.441 6.316 3.655 9.81 3.655s6.988-1.213 9.81-3.655l199.778-173.141v251.649c-.001 8.26-6.711 14.97-14.971 14.97z"/></svg>
                            <p class="inline-block pl-5 text-md font-semibold tracking-wider uppercase">Hello@qualon.xyz</p></a>
                        </div>          
                    </div>
                </div>
                           
            </div>
        </div>
    </div> 
        
</template>

<style>
form input, textarea{
        filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));            
    }
</style>
